import { useQuery } from "graphql-hooks";
import { Image, ResponsiveImageType, StructuredText } from "react-datocms";
import "./App.css"
import { useEffect, useState } from "react";

//ZlWuo3SRQVKMLU6MMVVJDg

function getGraphQL(id : string | null, locale: string | null, label : string | null) {
  const filter = (id)?`id: { eq: "${id}"}`:`tags: { matches: { pattern: "${label}" } }`;

  return `
  query article {
    article(
      filter: {
        ${filter}
      }
    ) {
      title(locale: ${locale ?? "en"})
      headline(locale: ${locale ?? "en"})

      paragraph1(locale: ${locale ?? "en"}) {
        value
      }

      image1 {
        responsiveImage(imgixParams: { fit: crop, w: 300, h: 300, auto: format }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }

      paragraph2(locale: ${locale}) {
        value
      }

      image2 {
        responsiveImage(imgixParams: { fit: crop, w: 300, h: 300, auto: format }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }    

      paragraph3(locale: ${locale}) {
        value
      }

      image3 {
        responsiveImage(imgixParams: { fit: crop, w: 300, h: 300, auto: format }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }    

      paragraph4(locale: ${locale}) {
        value
      }

    }
  }
  `;
}

function NullsafeImage({data} : {data : any}) {
  if (data === null) {
    return <></>
  }
  if (data.responsiveImage === null) {
    return <></>
  }
  
  return <Image className={"article-image"} data={data.responsiveImage}/>
  
}

function App() {
  const [queryParams, setQueryParams] = useState<{[index : string] : string | null}>({});

  useEffect(() => {
    // Create URLSearchParams object from the current URL
    const searchParams = new URLSearchParams(window.location.search);

    // Fetch specific parameters by name or loop through all parameters
    const params : {[index : string] : string} = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    setQueryParams(params);
  }, []); //

  const ARTICLE_QUERY = getGraphQL(queryParams['id'], queryParams["locale"], queryParams['label']);

  const { loading, error, data } = useQuery(ARTICLE_QUERY, {
    variables: {
      limit: 1
    }
  });
  if (loading) return <div>"Loading..."</div>;
  if (error) return <div>"Error..."</div>;
  return (
    <div className="App">
      <article>
        <h1>{data.article.title}</h1>
        <div>{data.article.headline}</div>
        <StructuredText data={data.article.paragraph1} />

        <NullsafeImage data={data.article.image1}/>

        <StructuredText data={data.article.paragraph2} />
        <NullsafeImage data={data.article.image2}/>


        <StructuredText data={data.article.paragraph3} />
        <NullsafeImage data={data.article.image3}/>


        <StructuredText data={data.article.paragraph4} />              
      </article>
    </div>
  );
}
export default App;